import Vue from 'vue'

Vue.directive('select-lazy-load', {
    bind(el, binding) {
        let selectDom = el.querySelector(".el-select-dropdown .el-select-dropdown__wrap");
        selectDom.addEventListener("scroll", function () {
            if (this.scrollHeight - this.scrollTop <= this.clientHeight) {
                binding.value();
            }
        });
    }
})