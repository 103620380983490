<template>
    <el-dialog title="权限设置"
               :visible="visible"
               :close-on-click-modal="false"
               width="1100px"
               @close="close">
        <el-tree ref="tree" :data="treeData" node-key="value" :show-checkbox="true">
            <span slot-scope="{ node, data }">
                <i v-if="data.Children" class="el-icon-folder" />
                <i v-else class="el-icon-document" />
                {{ node.label }}
            </span>
        </el-tree>
        <div slot="footer" class="dialog-footer">
            <span class="checkbox-block">
                <el-checkbox v-model="isSelectAll" @change="selectAll">
                    全选
                </el-checkbox>
            </span>
            <el-button type="primary" @click="save">提交</el-button>
            <el-button @click="close">关闭</el-button>
        </div>
    </el-dialog>
</template>

<script>
    import { submit } from "@/api/base";

    export default {
        name: "VersionAuth",
        data() {
            return {
                visible: false,
                param: { VersionID:0},
                treeData: [],
                selectRows: [],
                isSelectAll: false,
            };
        },
        methods: {
            init(type, id) {
                this.visible = true;
                this.param.VersionID = id;
                this.$nextTick(() => {
                    this.fetchData();
                });
            },
            async fetchData() {
                const { data } = await submit("/api/erpVersion/getVersionAuthInfo", this.param);
                this.treeData = data.AllAuthList;
                this.$nextTick(() => {
                    const checkedKeys = [];
                    (data.AuthList || []).forEach((key) => {
                        const node = this.$refs.tree.getNode(key);
                        if (node && node.isLeaf) {
                            checkedKeys.push(key);
                        }
                    });
                    this.$refs.tree.setCheckedKeys(checkedKeys);
                });
            },
            selectAll() {
                this.$refs.tree.setCheckedNodes(this.isSelectAll ? this.treeData : []);
            },
            async save() {
                let param = {};
                let checkKeys = this.$refs.tree.getCheckedKeys();
                let halfCheckKeys = this.$refs.tree.getHalfCheckedKeys();
                param.ids = [...checkKeys, ...halfCheckKeys];
                param.VersionID = this.param.VersionID;
                await submit("/api/erpVersion/addVersionAuth", param);
                this.$baseMessage("保存成功", "success");
                this.$emit("handleQuery");
                this.close();
            },
            close() {
                this.treeData = [];
                this.visible = false;
            },
        },
    };
</script>

<style lang="scss" scoped>
    .dialog-footer {
        position: relative;

        .checkbox-block {
            position: absolute;
            left: 28px;
            top: 8px;
        }
    }
</style>