module.exports = { 
	presets:[
		[
			"@vue/app",
			{
				useBuiltIns:"entry",
				polufills:[ "es6.promise"," es6.symbol " ]
			}
		]
	]
};