import Vue from 'vue';
import { numberFormat } from "@/utils/validate";

Vue.filter('formatInt', (value) => {
  return numberFormat(value, false, 0, false);
});

Vue.filter('formatDecimal', (value, digit = 2) => {
  return numberFormat(value, true, digit, false);
});

Vue.filter('formatDatetime', (value, type = '') => {
  if (!value) {
    return '';
  }
  if (type) {
    switch (type) {
      case 'stall_allocation': {
        let datetime = value.split(' ');
        let date = datetime.length > 0 ? datetime[0] : '';
        let time = datetime.length > 1 ? datetime[1] : '';
        time = time.substring(0, time.lastIndexOf(':'));
        return `<strong>${date}</strong> ${time}`;
      }
    }
  }
  return value;
});

Vue.directive('format', {
  bind(el, binding, vnode) {
    const ele = el.tagName === 'INPUT' ? el : el.querySelector('input');
    ele.handler = function () {
      if (binding.modifiers.int || binding.modifiers.float) {
        ele.value = numberFormat(ele.value, binding.modifiers.float ? true : false, binding.value);
      }
      if (vnode.componentInstance) {
        vnode.componentInstance.$emit('input', ele.value)
      } else {
        vnode.elm.dispatchEvent(new CustomEvent('input', ele.value))
      }
    }
    ele.addEventListener('input', ele.handler);
    ele.addEventListener('focus', ele.handler);
  },
  unbind: function (el) {
    el.removeEventListener('input', el.handler);
  }
});