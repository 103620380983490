<template>
    <div class="page-container">
        <div class="page-top">
            <el-row>
                <el-col :span="24">
                    <el-form :inline="true" :model="searchData">
                        <el-form-item label="公司名称：">
                            <el-input v-model="searchData.CompanyName" @keyup.enter.native="handleSearch" clearable
                                placeholder="请输入公司名称"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" icon="el-icon-search" @click="handleSearch">查询</el-button>
                        </el-form-item>
                    </el-form>
                </el-col>
            </el-row>
        </div>
        <div class="page-table">
            <el-table stripe border ref="customerTable" row-key="CustomerID" :data="tableData.items"
                :height="tableData.height" v-loading="tableData.loading"
                :header-cell-style="{ background: '#eef1f6', color: '#606266' }">
                <el-table-column type="selection" width="39" />
                <el-table-column label="公司名称" prop="CompanyName" />
                <el-table-column label="版本名称" prop="VersionName" />
                <el-table-column label="注册手机" prop="Mobile" />
                <el-table-column label="注册时间" prop="RegistTime" width="160" />
                <el-table-column label="付款时间" prop="PayTime" width="160" />
                <el-table-column label="到期时间" prop="ExpireTime" width="160" />
                <el-table-column label="公司类型" width="100" align="center" header-align="center">
                    <template slot-scope="{ row }">
                        {{ row.CustomerType == 0 ? '采购商' : '供应商' }}
                    </template>
                </el-table-column>
                <el-table-column label="审核状态" width="100" align="center" header-align="center">
                    <template slot-scope="{ row }">
                        {{ row.CheckFlag == 0 ? '待审核' : '审核通过' }}
                    </template>
                </el-table-column>
                <el-table-column label="是否锁定" width="100" align="center" header-align="center">
                    <template slot-scope="{ row }">
                        {{ row.ComLock == 0 ? '' : '是' }}
                    </template>
                </el-table-column>
                <el-table-column label="备注" prop="Remark" />
                <el-table-column label="操作" width="300" align="center" header-align="center">
                    <template slot-scope="scope">
                        <el-button plain size="mini" @click="handleShowRemark(scope.row)">
                            编辑
                        </el-button>
                        <el-button plain size="mini" @click="handleShowVersion(scope.row)">
                            版本
                        </el-button>
                        <el-button plain size="mini" @click="handleLock(scope.row)">{{
                            scope.row.ComLock == 0 ? '锁定' : '解锁' }}
                        </el-button>
                        <el-button plain size="mini" @click="handleLogin(scope.row)">
                            登录
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination background :total="tableData.total" :page-size="tableData.pageSize"
                :current-page="tableData.currentPage" :page-sizes="[10, 15, 20, 30, 50]"
                layout="prev, pager, next,total, sizes" @size-change="handleSizeChange"
                @current-change="handleCurrentChange" />
        </div>
        <div class="page-dialog">
            <el-dialog title="设置版本" width="600px" :visible.sync="versionData.visible" :close-on-click-modal="false">
                <div style="margin-bottom: 10px;">公司名称：{{ versionData.companyName }}</div>
                <el-select style="width: 100%;" v-model="versionData.versionId">
                    <el-option v-for="item in versionData.listData" :key="item.VersionID" :value="item.VersionID"
                        :label="item.VersionName" />
                </el-select>
                <div slot="footer" class="dialog-footer">
                    <el-button size="small" @click="handleCloseVersion">关闭</el-button>
                    <el-button type="primary" size="small" :loading="loading" @click="handleSaveVersion">确定</el-button>
                </div>
            </el-dialog>
            <el-dialog title="编辑" width="600px" :visible.sync="remarkData.visible" :close-on-click-modal="false">
                <el-form>
                    <el-form-item label="公司名称：">
                        {{ remarkData.companyName }}
                    </el-form-item>
                    <el-form-item label="付款时间：">
                        <el-date-picker v-model="remarkData.payTime" value-format="yyyy-MM-dd HH:mm:ss" style="width: 100%;"/>
                    </el-form-item>
                    <el-form-item label="到期时间：">
                        <el-date-picker v-model="remarkData.expireTime" value-format="yyyy-MM-dd HH:mm:ss" style="width: 100%;"/>
                    </el-form-item>
                    <el-form-item label="备注：">
                        <el-input v-model="remarkData.remark" type="textarea" :rows="5" />
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button size="small" @click="handleCloseRemark">关闭</el-button>
                    <el-button type="primary" size="small" :loading="loading" @click="handleSaveRemark">确定</el-button>
                </div>
            </el-dialog>
        </div>
    </div>
</template>
<script>
import { submit } from "@/api/base";

export default {
    name: 'erpAccount',
    data() {
        return {
            loading: false,
            searchData: {
                CompanyName: null
            },
            tableData: {
                loading: false,
                items: [],
                total: 0,
                pageSize: 15,
                currentPage: 1,
                height: window.innerHeight - 300
            },
            versionData: {
                visible: false,
                listData: [],
                companyName: '',
                customerId: null,
                versionId: null
            },
            remarkData: {
                visible: false,
                companyName: '',
                customerId: null,
                payTime: null,
                expireTime: null,
                remark: null
            }
        }
    },
    mounted() {
        this.$nextTick(async () => {
            await this.fetchData();
            await this.fetchVersions();
        });
    },
    methods: {
        async fetchVersions() {
            const { data } = await submit("/api/erpVersion/getList", {
                PageSize: 1000,
                CurrentPage: 1,
            });
            this.versionData.listData = data.datas;
        },
        async fetchData() {
            this.tableData.loading = true;
            const { data } = await submit("/api/erpCustomer/getList", {
                PageSize: this.tableData.pageSize,
                CurrentPage: this.tableData.currentPage,
                CompanyName: this.searchData.CompanyName
            }).finally(() => {
                this.tableData.loading = false;
            });

            this.tableData.total = data.total;
            this.tableData.items = data.datas;
        },
        handleSearch() {
            this.fetchData();
        },
        handleSizeChange(size) {
            this.tableData.pageSize = size;
            this.fetchData();
        },
        handleCurrentChange(page) {
            this.tableData.currentPage = page;
            this.fetchData();
        },
        handleShowVersion(row) {
            this.versionData.companyName = row.CompanyName;
            this.versionData.customerId = row.CustomerID;
            this.versionData.versionId = row.VersionID;
            this.versionData.visible = true;
        },
        handleCloseVersion(){
            this.versionData.visible = false;
        },
        async handleSaveVersion() {
            this.loading = true;
            await submit("/api/erpCustomer/setVersion", {
                CustomerID: this.versionData.customerId,
                VersionID: this.versionData.versionId
            }).then(()=>{
                this.fetchData();
                this.handleCloseVersion();
            }).finally(() => {
                this.loading = false;
            });
        },
        async handleLock(row) {
            this.$confirm(`${row.CompanyName} 将被${row.ComLock == 0?'锁定':'解锁'}，确定吗？`, '提示', {
                type: 'warning',
                closeOnClickModal: false,
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then(async () => {
                await submit("/api/erpCustomer/setComLock", {
                    CustomerID: row.CustomerID,
                    ComLock: row.ComLock == 0 ? 1 : 0
                }).then(() => {
                    this.fetchData();
                })
            });
        },
        async handleLogin(row){
            const { data } = await submit("/api/erpCustomer/getErpAuth", {
                CustomerID: row.CustomerID,
            });
            window.open(process.env.VUE_APP_ERP_URL + `login?token=${data.token}&redirect=${data.redirect}`, '_blank');
        },
        handleShowRemark(row){
            this.remarkData.companyName = row.CompanyName;
            this.remarkData.customerId = row.CustomerID;
            this.remarkData.payTime = row.PayTime;
            this.remarkData.expireTime = row.ExpireTime;
            this.remarkData.remark = row.Remark || '';
            this.remarkData.visible = true;
        },
        handleCloseRemark(){
            this.remarkData.visible = false;
        },
        async handleSaveRemark(){
            this.loading = true;
            await submit("/api/erpCustomer/setRemark", {
                CustomerID: this.remarkData.customerId,
                PayTime: this.remarkData.payTime,
                ExpireTime: this.remarkData.expireTime,
                Remark: this.remarkData.remark
            }).then(()=>{
                this.fetchData();
                this.handleCloseRemark();
            }).finally(() => {
                this.loading = false;
            });
        }
    }
}
</script>
<style lang="scss" scoped>
.page-top {
    padding: 6px 14px 0 14px;
}

.page-table {
    text-align: right;
    padding: 0 14px 0 14px;
}
</style>