<template>
  <div class="wrapper">
    <div class="error">
      <div>404</div>
      <div>您访问的页面不存在</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "404",
  data() {
    return {};
  },
  beforeCreate() {
    document.querySelector("body").style =
      "background:url('/static/img/background.png') no-repeat;background-size:100% 100%;background-attachment:fixed;margin:0;";
  },
  beforeDestroy() {
    document.querySelector("body").removeAttribute("style");
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  height: 100%;
  width: 100%;
  .error {
    width: 100%;
    padding-top: 15%;
    text-align: center;
    :first-child {
      font-family: fangsong;
      font-size: 120px;
      font-weight: 900;
      color: #fff;
    }
    :last-child {
      font-size: 25px;
      color: #ccc;
    }
  }
}
</style>