<template>
  <div class="page-container">
    <div class="page-top">
      <button-group :buttons="buttonConfig" @handleClick="callMethod"> </button-group>
      <search-form :searchForm="tableConfig.searchForm" @handleQuery="handleQuery"></search-form>
    </div>
    <div class="page-table">
      <grid-table ref="gridTable" :tableConfig="tableConfig" @selectionChange="handleSelectionChange" @editRow="handleShowDialog"> </grid-table>
    </div>
    <div class="page-dialog">
      <edit-dialog ref="editDialog" v-if="dialogVisible" :formConfig="editFormConfig" @handleQuery="handleQuery"></edit-dialog>
    </div>
    <div class="page-dialog">
      <version-auth ref="versionAuth" v-if="versionAuthDialogVisible" @handleQuery="handleQuery"></version-auth>
    </div>
  </div>
</template>

<script>
import { submit } from '@/api/base';
import VersionAuth from '@/views/erpVersion/components/VersionAuth.vue';
export default {
  name: 'pageList',
  components: {
    VersionAuth,
  },
  data() {
    return {
      routeParam: {},
      versionAuthDialogVisible: false,
      buttonConfig: [...(this.$route.meta.pageConfig.buttons || [])],
      tableConfig: { ...(this.$route.meta.pageConfig.table || {}) },
      selectRows: [],
      dialogVisible: false,
      editFormConfig: {},
    };
  },
  mounted() {
    this.routeParam = {
      ...(this.$route.query || {}),
      ...(this.$route.params || {}),
    };
  },
  activated() {
    var param = {
      ...(this.$route.query || {}),
      ...(this.$route.params || {}),
    };
    if (Object.keys(param).length > 0 && this.routeParam != param) {
      this.routeParam = param;
    }
  },
  watch: {
    routeParam: {
      deep: true,
      handler() {
        this.handleQuery();
      },
    },
  },
  methods: {
    handleQuery() {
      this.$refs.gridTable.searchParam = {
        ...this.$refs.gridTable.searchParam,
        ...this.tableConfig.searchForm.data,
        ...this.routeParam,
      };
      this.$refs.gridTable.fetchData();
    },
    handleSelectionChange(selection) {
      this.selectRows = selection;
    },
    callMethod(button) {
      let methods = this.$options.methods;
      const _this = this;
      methods[button.method](_this, button);
    },
    handleSearch(_this, button) {
      _this.handleQuery();
    },
    handleVersionAuth(_this, button) {
      if (!_this.selectRows || _this.selectRows.length == 0) {
        _this.$baseMessage('请选择数据', 'error');
        return false;
      }
      if (_this.selectRows.length > 1) {
        _this.$baseMessage('只能选择一行', 'error');
        return false;
      }
      _this.versionAuthDialogVisible = true;
      _this.$nextTick(() => {
        _this.editFormConfig = { ...button.bindForm };
        _this.$refs.versionAuth.init(button.label, _this.selectRows[0].VersionID);
      });
    },
    handleShowDialog(_this, button) {
      if (button.isNeedSelectRow && (!_this.selectRows || _this.selectRows.length == 0)) {
        _this.$baseMessage('请选择数据', 'error');
        return false;
      }
      if (!button.isMultiSelectRow && _this.selectRows.length > 1) {
        _this.$baseMessage('只能选择一行', 'error');
        return false;
      }
      _this.dialogVisible = true;
      _this.$nextTick(() => {
        _this.editFormConfig = { ...button.bindForm };
        _this.$refs.editDialog.init(button.label, _this.handleGetParam(_this, button.isNeedRowKey, button.paramJson));
      });
    },
    handleGetParam(_this, isNeedRowKey, paramJson) {
      let param = {};
      try {
        param = paramJson ? JSON.parse(paramJson) : {};
      } catch {
        param = {};
      }
      param = { ...param, ..._this.routeParam };
      if (isNeedRowKey) {
        param[_this.tableConfig.rowKey] = `?${_this.tableConfig.rowKey}?`;
      }
      for (let key in param) {
        if (param[key].indexOf('?') < 0) {
          continue;
        }
        var rowKey = param[key].slice(param[key].indexOf('?') + 1, param[key].lastIndexOf('?'));
        if (_this.selectRows.length == 0) {
          param[key] = '';
        } else if (_this.selectRows.length == 1) {
          param[key] = _this.selectRows[0][rowKey];
        } else {
          param[key] = '';
          param[key + 's'] = _this.selectRows.map((row) => row[rowKey]).join();
        }
      }
      return param;
    },
    handleToExcute(_this, button) {
      if (!_this.selectRows || _this.selectRows.length == 0) {
        _this.$baseMessage(`请选择要${button.label}的数据`, 'error');
        return false;
      }
      if (!button.isMultiSelectRow && _this.selectRows.length > 1) {
        _this.$baseMessage('只能选择一行', 'error');
        return false;
      }
      if (button.isNeedConfirm) {
        _this.$baseConfirm(`确定要${button.label}选中项吗？`, null, () => {
          this.handleExecute(_this, button);
        });
      } else {
        this.handleExecute(_this, button);
      }
    },
    async handleExecute(_this, button) {
      let param = {};
      if (!button.isMultiSelectRow) {
        param[_this.tableConfig.rowKey] = _this.selectRows[0][_this.tableConfig.rowKey];
      } else {
        param[_this.tableConfig.rowKey + 's'] = _this.selectRows.map((row) => row[_this.tableConfig.rowKey]).join();
      }
      await submit(button.url, param);
      _this.$baseMessage(`${button.label}成功`, 'success');
      _this.handleQuery();
    },
    handleSkip(_this, button) {
      if (button.isNeedSelectRow && (!_this.selectRows || _this.selectRows.length == 0)) {
        _this.$baseMessage('请选择数据', 'error');
        return false;
      }
      if (!button.isMultiSelectRow && _this.selectRows.length > 1) {
        _this.$baseMessage('只能选择一行', 'error');
        return false;
      }
      var params = _this.handleGetParam(_this, button.isNeedRowKey, button.paramJson);
      switch (button.skipType) {
        case 'url':
          window.open(button.url, '_blank');
          break;
        case 'routePage': {
          const { href } = _this.$router.resolve({
            name: button.toRouteName,
            params: params,
          });
          window.open(href, '_blank');
          break;
        }
        case 'routeTab':
          _this.$router.push({
            name: button.toRouteName,
            params: params,
          });
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.page-top {
  padding: 6px 14px 0 14px;
}
</style>
